<template>
  <v-app>
    <!-- Fonts -->
    <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">
    <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons">  
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">

    <!-- Navbar -->
    <v-app-bar
      app
      elevation='2'
      height='100'
      color=rgb(255,255,255)
      class="hidden-sm-and-down"
    >
      
    <v-app-bar-nav-icon :ripple='false' class='logo-nav' v-bind="size"><router-link :to='`/${$i18n.locale}/${navLinks[0].path}`'><img class ='logo' alt="Figue logo" src='./assets/logoKhoury.png'></router-link></v-app-bar-nav-icon>
    <v-toolbar-title class="title-nav">La Goutte d'Or</v-toolbar-title>

    <v-spacer></v-spacer>

    <div v-for='navLink in navLinks'
    v-bind:key = 'navLink.labelKey'
    >
      <v-btn 
        v-bind="size"
        class='btn-nav'
        :ripple='false'
        text
        group
        color=''
        href=''
        target=''
        medium
        >
        <span class='mr-2 btn-text'><router-link :to='`/${$i18n.locale}/${navLink.path}`'>{{ $t(navLink.labelKey)}}</router-link></span>
      </v-btn>      
    </div>

    <LanguageSwitch/>

    </v-app-bar>

    <!-- Nav hamburger -->

  <v-app-bar
      app
      elevation='2'
      height='100'
      color=rgb(255,255,255)
      class="hidden-md-and-up"
    >   
    <v-app-bar-nav-icon :ripple='false' class='logo-mini-nav' v-bind="size"><router-link :to='`/${$i18n.locale}/${navLinks[0].path}`'><img class ='logo' alt="Figue logo" src='./assets/logoKhoury.png'></router-link></v-app-bar-nav-icon>
   
    <v-toolbar-title class="title-nav">La Goutte d'Or</v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <div class="hidden-md-and-up"> -->
  <v-app-bar-nav-icon 
    @click="drawer = !drawer"
    class="hidden-md-and-up"
    >
  </v-app-bar-nav-icon>
</v-app-bar>

<v-sheet class="overflow-hidden">  
<v-navigation-drawer
      v-model="drawer"
      temporary
      right
      fixed
>
      <v-list-item>
        <v-list-item-avatar
        :ripple='false'>
          <img alt="Figue logo" src='./assets/logoKhoury_2.png'>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="title-mini-nav">La Goutte d'Or</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for='navLink in navLinks'
          :key = 'navLink.labelKey'          
        >
          <v-list-item-content>
            <v-list-item-title class='mini-nav-text'><router-link :to='`/${$i18n.locale}/${navLink.path}`'>{{ $t(navLink.labelKey)}}</router-link></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    <LanguageSwitch
    class="css-btn"/>

    </v-navigation-drawer>
</v-sheet>


    <!-- Content -->
    <v-main>
    <transition name='fade' mode='out-in'>
    <router-view/>
    </transition>
    </v-main>

    <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>&copy; Traiteur La Goutte d'Or</strong>
    </v-col>
  </v-footer>

  </v-app>
</template>

<script>
// import Home from './views/Home.vue';
// import Header from './components/Header.vue'
// import Galerie from './components/Galerie.vue'
import LanguageSwitch from './components/LanguageSwitch'

export default {
  name: 'App',

  components: {
    // Home,
    // Header,
    // Galerie
    LanguageSwitch
  },

  data: () => ({
    navLinks: [
      {
        labelKey: 'nav.home',
        path:'',
        icon: 'home',
      },
      {
        labelKey: 'nav.options',
        path:'formules',
        icon: 'formule',    
      },
      {
        labelKey: 'nav.pictures',
        path:'picture',
        icon: 'picture',   
      },
      {
        labelKey: 'nav.partners',
        path:'partners',
        icon: 'partners',
      },
      {
        labelKey: 'nav.contact',
        path:'contact',
        icon:'contact',  
      }
    ],
    drawer: null
  }),
  computed: {
    size () {
      const size = {xs:'x-small',sm:'large',md:'x-large', lg:'x-large',xl:'x-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    }
  }
};
</script>

<style>
  .logo {
      /* display: inline-block;
      vertical-align: top; */
      width: 50px;
      height: 70px;
      margin-left: 25px;
      margin-right: 5px;
      /*margin-top: 2px; */
  }

  .v-toolbar__content{
    border-bottom: 3px solid rgb(236, 219, 68);
  }

  .title-nav {
    font-family: 'Dosis', sans-serif;
    color:rgb(236, 219, 68);
    /* font-weight: 400; */
    font-size: 2rem !important;
  }

  a:focus{
    color:rgb(236, 219, 68) !important;
  }

  .title-mini-nav {
    font-family: 'Dosis', sans-serif;
    color:rgb(236, 219, 68);
    font-size: 1.3rem !important;
  }

  .mini-nav-text{
    font-family: 'Raleway', sans-serif;
    text-align: center;
  }

  .logo-mini-nav{
    /* flex: 1 0 auto; */
      width: 50px;
      height: 70px;
      margin-left: 25px;
      margin-right: 15px;
    
  }

  a, .btn-nav {
    font-family: 'Raleway', sans-serif;
    background-color: transparent !important;
    text-decoration: none;
    color: #393939 !important;
  }

  .btn-nav:before, .logo-nav::before {
    background-color: transparent;
    display: none;
  }

  .btn-nav:hover:before, .logo-nav:hover:before {
    background-color: transparent;
  }

  .btn-text {
    border-bottom: 3px solid transparent;
    transition: 0.1s;
  }

  .btn-text:after {
    border-bottom: 3px solid rgb(236, 219, 68);
    transition: left 250ms ease-in-out, right 250ms ease-in-out;
    opacity:0;
  }

  .btn-text:hover:after {
    opacity:1;
  }

  .btn-text:hover {
    border-bottom: 3px solid rgb(236, 219, 68);
    padding-bottom: 3px;
  }

  .fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

h2{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

.css-btn{
  margin-top: 50%;
  text-align: center;
  font-size: 14px;
}
</style>