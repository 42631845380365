import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
// import i18n from 'vue-i18n'
import i18n from './i18n'


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

let language = to.params.lang;
if(!language) {
  language= 'en'
}

i18n.locale = language;
next()
})

new Vue({
  router,
  render: h => h(App),

  data:{
    errors:[],
    name: null
  },

  vuetify,
  i18n,

  methods: {
    
  }
}).$mount('#app')
