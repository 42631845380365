<template>
    <button
      href="#"
      v-bind:class="{ active: selected, middleButton: isMiddleButton }"
      class="flex items-center btn-language"
      @click.prevent="$emit('language-selected', language)"     
    >
      <span>{{language.toUpperCase()}}</span>      
    </button>
</template>

<script>
export default {
    name: 'LanguageSelector',
    props: {
        language: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isMiddleButton: false
        }
    },
    created() {
        this.isMiddleButton= this.language === 'nl'
    }

}
</script>

<style scoped>

.btn-language:hover {
    color:  rgb(231, 222, 91);
}

.btn-language {
    margin: 0 10px 0 10px;
    outline-style: none;
    color: rgb(189, 189, 189);
}

.active{
    color:inherit;
}

.middleButton{
    padding: 0 10px 0 10px;
    border-right: 1px solid rgb(116, 115, 115);
    border-left: 1px solid rgb(116, 115, 115);
    margin: 0;
}
</style>