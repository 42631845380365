import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../i18n'
import Home from '../views/Home.vue'


Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (h) { return h('router-view') } 
    },
    children: [ 
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../components/Contacts.vue')
      },
      {
        path: 'picture',
        name: 'picture',
        component: () => import('../components/Galerie.vue')
      },
      {
        path: 'formules',
        name: 'formules',
        component: () => import('../components/Formules.vue')
      },
      {
      path: 'partners',
      name: 'partners',
      component: () => import('../components/Partner.vue')
      },
      {
      path: '*',
      name: '404',
      component: () => import('../views/NotFound.vue')
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
