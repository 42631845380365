<template>
  <div class="relative">
      <language-selector language="fr" v-on:language-selected="setLocale" v-bind:selected="frSelected" hreflang="fr"/>
      <language-selector language="nl" v-on:language-selected="setLocale" v-bind:selected="nlSelected" hreflang="nl"/>
      <language-selector language="en" v-on:language-selected="setLocale" v-bind:selected="enSelected" hreflang="en"/>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import LanguageSelector from './LanguageSelector.vue';
export default {
  mixins: [ clickaway ],
  components: {
      'language-selector': LanguageSelector
  },
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      frSelected: true,
      nlSelected: false,
      enSelected: false
    }
  },
  created: function () {
    this.setLocale('fr');
  },
  methods: {
    setLocale(locale) {
        if(this.$i18n.locale === locale) return;

        this.$i18n.locale = locale
        this.isSelected = true;
        this.$router.push({
            params: { lang: locale }
        });
        this.setSelectedButton(locale);
    },
    setSelectedButton(locale) {
        if(locale === 'fr') {
            this.frSelected = true;
            this.enSelected = false;
            this.nlSelected = false;
        }
        else if(locale === 'en') {
            this.frSelected = false;
            this.enSelected = true;
            this.nlSelected = false;
        }
        else if(locale === 'nl') {
            this.frSelected = false;
            this.enSelected = false;
            this.nlSelected = true;
        }
    }
  }
}
</script>

<style scoped>


</style>